<template>
  <div>
    <banner title="Blog Page" />
    <v-container class="my-15">
      <single-blog :selectedBlog="selectedBlog" />
      <v-row justify="center" align="center">
        <v-col
          max-width="200"
          style="position:relative"
          align-self="center"
          v-for="blog in allBlogs"
          :key="blog.slug"
          class="my-8"
          lg="5"
          md="8"
          sm="11"
          :v-if="conditionalRemoveCardFromList(blog)"
        >
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card @click="chosenBlog(blog)" class="mx-auto" elevation="10">
                <v-img :src="blog.picture" height="200" position="center top" :alt="blog.title">
                  <v-card-title class="card_title" v-if="!isMobile">
                    {{ blog.title }}
                    <div class="ml-auto">{{ blog.date_created }}</div>
                  </v-card-title>
                  <div v-else class="card_title">
                    <v-card-title>
                      {{ blog.title }}
                    </v-card-title>
                    <v-card-subtitle>{{ blog.date_created }}</v-card-subtitle>
                  </div>
                </v-img>
                <v-fade-transition>
                  <v-overlay v-if="hover" :value="hover" absolute>
                    <div class="text-center">
                      {{ blog.intro_text }}
                    </div>
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
import SingleBlog from "@/components/Blog.vue";

export default {
  name: "Blogs",
  components: { Banner, SingleBlog },
  data: () => ({
    selectedCategory: ""
  }),
  metaInfo() {
    return {
      title: "Blog Page",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "In need of inspiration? Check Out Our Blogs!"
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: "In need of inspiration? Check Out Our Blogs!"
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: "GraceWay Blog Page"
        },
        { vmid: "robots", name: "robots", content: "index,follow" },
        {
          vmid: "og:url",
          property: "og:url",
          content: "https://gracewayradio.com/blog"
        }
      ]
    };
  },
  watch: {
    selectedBlog() {
      if (this.selectedBlog === null) {
        this.$router.replace({ params: { blogSlug: undefined } });
      }
    }
  },
  computed: {
    allBlogs() {
      return this.$store.state.blog.allBlogs;
    },
    selectedBlog() {
      return this.$store.state.blog.selectedBlog;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    conditionalRemoveCardFromList(item) {
      return item?.title !== this.selectedBlog?.title;
    },
    chosenBlog(blog) {
      this.$store.dispatch("blog/setChosenBlog", blog.slug);
      this.$router.push({ name: "Blog", params: { blogSlug: blog.slug } });
    },
    getBlogs() {
      this.$store.dispatch("blog/getBlogs");
    }
  },
  mounted() {
    if (this.$route?.params?.blogSlug) {
      this.$store.dispatch("blog/setChosenBlog", this.$route?.params?.blogSlug);
      // this.$router.push({ name: "Blog", params: { blogSlug: this.$route?.params?.blogSlug } });
    }
    this.getBlogs();
  },
};
</script>

<style lang="scss" scoped>
.card_title {
  background-color: #1e90ff;
  color: white;
  opacity: 0.7;
}
#box-shadow {
  -webkit-box-shadow: -10px 10px 19px 2px rgba(17, 112, 136, 0.25);
  box-shadow: -10px 10px 19px 2px rgba(17, 112, 136, 0.25);
  border-radius: 10px;
}
</style>
